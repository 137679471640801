import "./Disabled.scss";

export default function Disabled() {
  return (
    <main className="utility disabled">
      <img src="/images/logo-medium.svg" alt="logo" />
      <p>새단장 중입니다.</p>
      <p>다음에 만나 <span className="red">요! Yo!</span> </p>
      <p>dj 심청이 drop the beat ㅋㅋ</p>
    </main>
  )
}
